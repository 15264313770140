import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { SportElement, TopLeagueListElement } from 'web/src/modules/sportline/types';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import {
  useSportlineTopLeaguesStore,
  useSportlineTopZeroMarginStore,
} from 'web/src/modules/sportline/submodules/top/store';

interface UseSportlineCardsDataComposable {
  isReady: Ref<boolean>;
  topLeagues: Ref<TopLeagueListElement[]>;
  zeroMarginEventsList: Ref<SportElement[]>;
  initialRequests(): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  syncBackgroundRequests(): Promise<void>;
}

export function useSportlineCardsData(): UseSportlineCardsDataComposable {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_DISABLED) {
    return {
      isReady: ref(true),
      topLeagues: ref<TopLeagueListElement[]>([]),
      zeroMarginEventsList: ref<SportElement[]>([]),
      initialRequests() { return Promise.resolve(); },
      setBackgroundUpdateEnabled() { return Promise.resolve(); },
      syncBackgroundRequests() { return Promise.resolve(); },
    };
  }

  const { isBetSwipeTopEventsLayoutEnabled } = useSportlineLayoutSettings();

  const sportlineTopLeaguesStore = useSportlineTopLeaguesStore();
  const isTopLeaguesReady = toRef(sportlineTopLeaguesStore, 'isReady');
  const topLeagues = toRef(sportlineTopLeaguesStore, 'topLeagues');

  const sportlineTopZeroMarginStore = useSportlineTopZeroMarginStore();
  const isZeroMarginReady = toRef(sportlineTopZeroMarginStore, 'isReady');
  const zeroMarginEventsList = toRef(sportlineTopZeroMarginStore, 'zeroMarginEventsList');

  // @TODO check this logic. Maybe we should not await this data to display page
  const isReady = computed(() => (
    !isBetSwipeTopEventsLayoutEnabled.value
    || (isTopLeaguesReady.value && isZeroMarginReady.value)
  ));

  return {
    isReady,
    topLeagues,
    zeroMarginEventsList,
    async initialRequests(): Promise<void> {
      if (!isBetSwipeTopEventsLayoutEnabled.value) { return; }

      await Promise.all([
        sportlineTopLeaguesStore.initialRequests(),
        sportlineTopZeroMarginStore.initialRequests(),
      ]);
    },
    async syncBackgroundRequests(): Promise<void> {
      if (!isBetSwipeTopEventsLayoutEnabled.value) { return; }

      await Promise.all([
        sportlineTopLeaguesStore.syncBackgroundRequests(),
        sportlineTopZeroMarginStore.syncBackgroundRequests(),
      ]);
    },
    async setBackgroundUpdateEnabled(value: boolean): Promise<void> {
      if (!isBetSwipeTopEventsLayoutEnabled.value) { return; }

      await Promise.all([
        sportlineTopLeaguesStore.setBackgroundUpdateEnabled(value),
        sportlineTopZeroMarginStore.setBackgroundUpdateEnabled(value),
      ]);
    },
  };
}
